import React, { useCallback, useContext, useState } from 'react';
import { Button, Spinner, useStyles2, LinkButton, Alert, Icon } from '@grafana/ui';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { installDashboards, installDatasources, uninstallDashboards } from 'lib/backend';
import { faro } from '../module';
import { VerifiedDashboard, DemoInfraDashboardVariant } from 'types/types.dashboard';
import { AppContext } from './App';
import useRudderStack from 'lib/rudderstack';

interface ErrorResp {
  err: string;
  details?: string;
}

export function DashboardCard({
  maskDirection,
  dashboard,
  onUninstall,
}: {
  maskDirection: 'left' | 'right';
  onUninstall?: (e: React.MouseEvent) => void;
  dashboard: VerifiedDashboard;
}) {
  const { key, variant, screenshot, title, description, features, redirectUrl, isInstalled } = dashboard;
  const rs = useRudderStack();
  const s = useStyles2(getStyles);
  const [syncLoading, setSyncLoading] = useState(false);
  const [uninstalling, setUninstalling] = useState(false);
  const [installed, setInstalled] = useState(isInstalled);
  const [reset, setReset] = useState(false);
  const [error, setError] = useState<ErrorResp | null>(null);
  const ctx = useContext(AppContext);

  const targetUri = redirectUrl instanceof Function ? redirectUrl(ctx) : redirectUrl;
  console.log(error);

  const triggerInstall = useCallback(async () => {
    faro.api.pushEvent('install_triggered', { variant: variant });
    setError(null);
    setSyncLoading(true);
    try {
      let dsResp = await installDatasources();
      if (dsResp.status !== 200) {
        let err = await dsResp.json();
        setError(err);
        return;
      }
      let dashResp = await installDashboards(variant);
      if (dashResp.status !== 200) {
        let err = await dashResp.json();
        setError(err);
        return;
      }
    } catch (err) {
      // Network error, display message from browser directly
      setError({
        err: `${err}`,
      });
    } finally {
      setSyncLoading(false);
    }
    window.location.href = targetUri;
  }, [setSyncLoading, variant, setError, targetUri]);
  const syncDashboards = useCallback(async () => {
    setError(null);
    setSyncLoading(true);
    rs.trackRudderStackEvent('grafana_plugin_demo_dashboards_synced', { variant: variant });
    try {
      let dashResp = await installDashboards(variant);
      if (dashResp.status !== 200) {
        let err = await dashResp.json();
        setError(err);
        return;
      }
    } catch (err) {
      // Network error, display message from browser directly
      setError({
        err: `${err}`,
      });
      return;
    } finally {
      setSyncLoading(false);
    }
    setReset(true);
    setTimeout(() => {
      setReset(false);
    }, 10e3);
  }, [setError, variant, setSyncLoading, setReset, rs]);

  const callback = onUninstall;

  const removeDashboards = useCallback(
    async (e: React.MouseEvent) => {
      setError(null);
      setUninstalling(true);
      rs.trackRudderStackEvent('grafana_plugin_demo_dashboards_removed', { variant: variant });
      try {
        let dashResp = await uninstallDashboards(variant);
        if (dashResp.status !== 200) {
          let err = await dashResp.json();
          setError(err);
          return;
        }
      } catch (err) {
        // Network error, display message from browser directly
        setError({
          err: `${err}`,
        });
        return;
      } finally {
        setUninstalling(false);
      }
      setInstalled(false);
      callback?.(e);
    },
    [setError, variant, setInstalled, setUninstalling, callback, rs]
  );
  const isDashboards = Object.values(DemoInfraDashboardVariant).includes(key as DemoInfraDashboardVariant);
  return (
    <div className={s.box}>
      <div className={s.split}>
        {maskDirection === 'right' && (
          <div className={s.maskRight}>
            <img className={s.screenshot} src={screenshot} />
          </div>
        )}
        <div className={s.vsplit}>
          <h2>{title}</h2>
          <p>{description}</p>
          <ul className={s.list}>
            {features.map((n, id) => (
              <li key={id}>{n}</li>
            ))}
          </ul>
          <div className={s.spacer}></div>
          <div>
            {error && (
              <Alert severity="error" title={error.err}>
                Your instance might not be fully started up, please try again in a few moments.
                <details>
                  <summary>Error Details</summary>
                  {error.details}
                </details>
              </Alert>
            )}
            <div
              className={css`
                display: flex;
                gap: 1em;
              `}
            >
              {!installed && (
                <Button onClick={triggerInstall} data-testid={`install-${key}`}>
                  Install Dashboards
                  {syncLoading && <Spinner />}
                </Button>
              )}
              {installed && (
                <>
                  <LinkButton fill="outline" icon="apps" data-testid={`view-${key}`} href={targetUri}>
                    Go to {isDashboards ? 'dashboards' : 'app'}
                  </LinkButton>
                  {isDashboards && (
                    <>
                      <Button
                        fill="outline"
                        icon="process"
                        variant="success"
                        onClick={syncDashboards}
                        tooltip="Messed something up? No worries, you can reset the dashboards here"
                        data-testid={`reset-${key}`}
                      >
                        Reset dashboards {syncLoading && <Spinner />} {reset && <Icon name="check" />}
                      </Button>
                      <Button
                        fill="outline"
                        icon="trash-alt"
                        variant="destructive"
                        onClick={(e) => removeDashboards(e)}
                        tooltip="Remove the created dashboard folders and all dashboards within it"
                        data-testid={`remove-${key}`}
                      >
                        Remove {uninstalling && <Spinner />}
                      </Button>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {maskDirection === 'left' && (
          <div className={s.maskLeft}>
            <img className={s.screenshot} src={screenshot} />
          </div>
        )}
      </div>
    </div>
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  box: css`
    background-color: ${theme.colors.background.secondary};
    margin-top: ${theme.spacing(2)};
    padding: ${theme.spacing(2)};
  `,
  maskLeft: css`
    mask-image: linear-gradient(to right, transparent 20%, rgba(0, 0, 0, 1) 80%);
  `,
  maskRight: css`
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 20%, transparent 80%);
  `,
  split: css`
    display: flex;
    justify-content: space-between;
  `,
  screenshot: css`
    height: 20em;
  `,
  list: css`
    padding-left: 2em;
  `,
  spacer: css`
    flex-grow: 1;
  `,
  vsplit: css`
    display: flex;
    flex-direction: column;
  `,
});
